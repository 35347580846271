<template>
	<div class="mx-auto m-50" style="max-width: 800px;">

		<!-- Header -->
		<h3 class="mt-25 mb-5 text-center">Publique seu Conteúdo</h3>
		<!-- <h6 class="text-center font-regular"></h6> -->

		<div class="my-50" style="max-width: 800px;">

			<!-- Steps -->
			<a-steps v-if="isDesktop" size="small" v-model="step" direction="horizontal">
				<a-step :title="isDesktop ? 'Conteúdo' : ''" />
				<a-step :title="isDesktop ? 'Informações do Conteúdo' : ''" />
				<a-step :title="isDesktop ? 'Preços e Planos' : ''" />
				<a-step :title="isDesktop ? 'Mídias' : ''" />
			</a-steps>
			<!-- / Steps -->

		</div>
		<!-- / Header -->
		<!-- Wizard form cards -->
		<div class="mb-50">

			<!-- Step 2 : Account -->
			<a-card v-if="step == 0" :bordered="false" class="header-solid">

				<h5 class="font-regular text-center">Que tipo de conteúdo você quer criar?</h5>
				<p class="text-center">Selecione o formato do seu conhecimento e comece a construir seu curso, evento ou
					outro tipo de material:</p>

				<a-form :form="form" class="mt-30" :hideRequiredMark="true">
					<a-row type="flex" :gutter="[24]">
						<a-col :span="24" :md="20" :lg="18" class="mx-auto">
							<a-row class="checkbox-group" type="flex" :gutter="[50]">
								<a-col :span="8">
									<div class="checkbox-label" :class="[checkedList.includes('event') ? 'active' : '']"
										@click="toggleCheckbox('event')">
										<lord-icon
											src="https://cdn.lordicon.com/bvymuvni.json"
											trigger="hover"
											colors="primary:#ffffff,secondary:#b4b4b4"
											style="width:42px;height:42px">
										</lord-icon>
									</div>
									<h6>Eventos e Palestras</h6>
								</a-col>
								<a-col :span="8">
									<div class="checkbox-label" :class="[checkedList.includes('course') ? 'active' : '']"
										@click="toggleCheckbox('course')">
										
										<lord-icon
											src="https://cdn.lordicon.com/dqrpfjei.json"
											trigger="hover"
											colors="primary:#ffffff,secondary:#e4e4e4"
											style="width:42px;height:42px">
										</lord-icon>
									</div>
									<h6>Cursos e Treinamentos</h6>
								</a-col>
								<a-col :span="8">
									<div class="checkbox-label" :class="[checkedList.includes('product') ? 'active' : '']"
										@click="toggleCheckbox('product')">
										<lord-icon
											src="https://cdn.lordicon.com/zxcukefq.json"
											trigger="hover"
											colors="primary:#ffffff,secondary:#e4e4e4"
											style="width:42px;height:42px">
										</lord-icon>
									</div>
									<h6>Materiais de Aprendizado</h6>
								</a-col>
							</a-row>
							<a-checkbox-group class="d-none" v-model="checkedList" :options="checkboxOptions" />
						</a-col>
					</a-row>
					<a-row :gutter="[24]" style="margin-top: 25px;">
						<a-col :span="24" class="text-right">
							<a-button type="primary" @click="moveStep(1)" class="px-25" :disabled="isDisabled"
								:block="!isDesktop">Avançar</a-button>
						</a-col>
					</a-row>
				</a-form>
			</a-card>

			<!-- Step 1 : About -->
			<a-card v-else-if="step == 1" :bordered="false" class="header-solid">

				<h5 class="font-regular text-center">Hora de dar vida ao seu {{ renameTitle(checkedList[0]) }}!</h5>
				<p class="text-center">Insira informações essenciais sobre seu {{ renameTitle(checkedList[0]) }} para atrair seu público
				</p>

				<!-- form Curso-->
				<a-form v-if="checkedList[0] === 'course'" class="mt-30" :hideRequiredMark="true" @submit="handleSubmit">
					<a-row :gutter="[24]">
						<a-col :span="24" :lg="24">
							<a-form-item class="mb-10" label="Categoria do Curso" :colon="false">
								<a-select v-model="course.categoryId" placeholder="Selecione a categoria" option-filter-prop="children">
									<a-select-option value="4f9d8f19-5d69-48ae-a168-976ab344298b">Vendas</a-select-option>
									<a-select-option value="7c14fc12-b18e-4c0b-8303-ce8006721935">Negócios</a-select-option>
									<a-select-option value="974c12b6-ebc5-4367-bba4-d58af5de7eba">Marketing</a-select-option>
									<a-select-option value="f7052913-d0b4-44f5-88ce-56695320cfd4">Influência</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="24" :md="24">
							<a-form-item class="mb-10" label="Título de Curso" :colon="false">
								<a-input 
									v-decorator="['note', { rules: [{ required: true, message: 'Please input your note!' }] }]"
									v-model="course.title" 
									placeholder="Ex: Curso de ..." 
								/>
							</a-form-item>
							<a-form-item class="mb-10" label="Breve Descrição" :colon="false">
								<a-textarea 
									v-model="course.description" :rows="5"
									placeholder="Descreva seu conteúdo, conte-nos o que os membros poderão aprender e como isso beneficiará suas carreiras. Seja claro e cativante! :)" />
							</a-form-item>
							<a-form-item class="mb-10" label="Nome do Instrutor" :colon="false">
								<a-input 
									v-model="course.descriptionType" 
									placeholder="Ex: João" />
							</a-form-item>
						</a-col>
					</a-row>
					<a-row :gutter="[24]">
						<a-col :span="12">
							<a-button @click="moveStep(-1)" class="px-25">VOLTAR</a-button>
						</a-col>
						<a-col :span="12" class="text-right">
							<a-button type="primary" @click="moveStep(1)" class="px-25">AVANÇAR</a-button>
						</a-col>
					</a-row>
				</a-form>


				<!-- form Curso-->
				<a-form v-if="checkedList[0] === 'event'" :form="form" class="mt-30" :hideRequiredMark="true">
					<a-row :gutter="[24]">
						<a-col :span="24" :lg="12">
							<a-form-item class="mb-10" label="Categoria do Evento" :colon="false">
								<a-select v-model="event.descriptionType" placeholder="Selecione um tipo">
									<a-select-option value="Seminários e Workshops">Seminários e Workshops</a-select-option>
									<a-select-option value="Palestras e Conferências">Palestras e Conferências</a-select-option>
									<a-select-option value="Treinamentos">Treinamentos</a-select-option>
									<a-select-option value="Retiros de Desenvolvimento Pessoal">Retiros de Desenvolvimento Pessoal</a-select-option>
									<a-select-option value="Feiras e Exposições">Feiras e Exposições</a-select-option>
									<a-select-option value="Mentoria e Coaching Individual">Mentoria e Coaching Individual</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="12">
							<a-form-item class="mb-10" label="Formato do Evento" :colon="false">
								<a-select v-model="event.format" placeholder="Selecione o Formato" option-filter-prop="children">
									<a-select-option value="PRESENTIAL">Presencial</a-select-option>
									<a-select-option value="ONLINE">Online</a-select-option>
									<a-select-option value="HYBRID">Híbrido</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="24" :md="24">
							<a-form-item class="mb-10" label="Título do Evento" :colon="false">
								<a-input v-model="event.name" placeholder="Ex: " />
							</a-form-item>
							<a-form-item class="mb-10" label="Descrição" :colon="false">
								<a-textarea v-model="event.description" :rows="5"
									placeholder="Descreva seu conteúdo, conte-nos o que os membros poderão aprender e como isso beneficiará suas carreiras. Seja claro e cativante! :)" />
							</a-form-item>
							<a-form-item class="mb-10" label="Nome do Instrutor ou Palestrante" :colon="false">
								<a-input placeholder="ex: João" />
							</a-form-item>
						</a-col>
					</a-row>
					<a-row :gutter="[24]">
						<a-col :span="12" :sm="12" :md="12">
							<a-form-item class="mb-10" label="Início do Evento" :colon="false">
								<a-date-picker v-model="event.startDate">
									<template slot="renderExtraFooter">
										Data de Início
									</template>
								</a-date-picker>
							</a-form-item>
						</a-col>
						<a-col :span="12" :sm="12" :md="12">
							<a-form-item class="mb-10" label="Fim do Evento" :colon="false">
								<a-date-picker v-model="event.date">
									<template slot="renderExtraFooter">
										Data de Término
									</template>
								</a-date-picker>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row :gutter="[24]">
						<a-col :span="16" :md="16">
							<a-form-item class="mb-10" label="Localização" :colon="false">
								<a-input v-model="event.address" placeholder="Ex: Natal" />
							</a-form-item>
						</a-col>
						<a-col :span="8" :lg="8">
							<a-form-item class="mb-10" label="Nº de Vagas" :colon="false">
								<a-input-number v-model="event.vacancies"/>
							</a-form-item>
						</a-col>
						<a-col :span="24" :lg="24">
							<a-form-item class="mb-10" label="Link Adicional so Evento" :colon="false">
								<a-input v-model="event.checkoutLink" placeholder="Link de checkout ou página do evento"/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-row :gutter="[24]" style="margin-top: 15px;">
						<a-col :span="12">
							<a-button @click="moveStep(-1)" class="px-25">VOLTAR</a-button>
						</a-col>
						<a-col :span="12" class="text-right">
							<a-button type="primary" @click="moveStep(1)" class="px-25">AVANÇAR</a-button>
						</a-col>
					</a-row>
				</a-form>


				<!-- form Curso-->
				<a-form v-if="checkedList[0] === 'product'" :form="form" class="mt-30" :hideRequiredMark="true">
					<a-row :gutter="[24]">
						<a-col :span="24" :lg="24">
							<a-form-item class="mb-10" label="Tipo do Material" :colon="false">
								<a-select v-model="product.productTypeId" placeholder="Selecione o Tipo" option-filter-prop="children">
									<a-select-option value="73e2afac-216f-4f2c-a22a-343e23431ef3">Audiobooks</a-select-option>
									<a-select-option value="412ab319-bd34-4009-b9b2-1d99e71ef9aa">Artigos</a-select-option>
									<a-select-option value="48361c53-5ad5-47a0-bcf8-0b8aea443475">Ebooks</a-select-option>
									<a-select-option value="79842323-02bc-44d8-8078-9985030fae63">Infográficos</a-select-option>
									<a-select-option value="d98fdbdf-297a-4ee6-86bd-6b8b450e9324">Podcast</a-select-option>
									<a-select-option value="465db9c4-e13d-4dc8-82d7-8314ce7ea4a1">Shorts Youtube</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :span="24" :md="24">
							<a-form-item class="mb-10" label="Título de Conteúdo" :colon="false">
								<a-input v-model="product.name" placeholder="Ex: Título Contéudo" />
							</a-form-item>
							<a-form-item class="mb-10" label="Descrição" :colon="false">
								<a-textarea v-model="product.description" :rows="5"
									placeholder="Descreva seu conteúdo, conte-nos o que os membros poderão aprender e como isso beneficiará suas carreiras. Seja claro e cativante! :)" />
							</a-form-item>
							<a-form-item class="mb-10" label="Nome do Instrutor" :colon="false">
								<a-input v-model="product.descriptionType" placeholder="EX: João" />
							</a-form-item>
						</a-col>
					</a-row>
					<a-row :gutter="[24]">
						<a-col :span="24" :md="24">
							<a-form-item class="mb-10" label="Link de Acesso ou Download" :colon="false">
								<a-input v-model="product.link" placeholder="ex: https://chat.openai.com/c/4e7244bf-75cb-477a-9ce3-e4c99ae9f567" />
							</a-form-item>
						</a-col>
					</a-row>
					<a-row :gutter="[24]" style="margin-top: 15px">
						<a-col :span="12">
							<a-button @click="moveStep(-1)" class="px-25">VOLTAR</a-button>
						</a-col>
						<a-col :span="12" class="text-right">
							<a-button type="primary" @click="moveStep(1)" class="px-25">AVANÇAR</a-button>
						</a-col>
					</a-row>
				</a-form>

			</a-card>

			<!-- Step 3 : Address -->
			<a-card v-else-if="step == 2" :bordered="false" class="header-solid">

				<h5 class="font-regular text-center">Quanto vale o seu conhecimento?</h5>
				<p class="text-center">Em breve você poderá definir preços e condições para aquisição do seu conteúdo. Atualmente todo conteúdo será distribuído gratuitamente.</p>

				<a-form :form="form" class="mt-30" :hideRequiredMark="true">
					<a-row :gutter="[24]"
						style="padding: 24px; border: 1px solid #FFFFFF; margin: 2px; border-radius: 10px; background-color: #1d212c;">
						<a-col :span="24" :md="24">
							<a-checkbox v-model:checked="checked" style="color: #FFFFFF; font-size: medium;">Este conteúdo
								será distribuido gratuitamente para os membros da A LIGA.</a-checkbox>
						</a-col>
					</a-row>
					<br>
					<!-- <a-row :gutter="[24]">
						<a-col :span="24" :md="24">
							<a-form-item class="mb-10" label="Preço Base" :colon="false">
								<a-input placeholder="eg. Street 111" :disabled="checked" />
							</a-form-item>
						</a-col>
					</a-row> -->
					<a-row :gutter="[24]" style="margin-top: 25px;">
						<a-col :span="12">
							<a-button @click="moveStep(-1)" class="px-25">Voltar</a-button>
						</a-col>
						<a-col :span="12" class="text-right">
							<a-button type="primary" class="px-25" @click="saveContent()" :loading="isSaveProgress">Avançar</a-button>
							<!-- <a-button type="primary" class="px-25" @click="moveStep(1)" :loading="isSaveProgress">Avançar</a-button> -->
						</a-col>
					</a-row>
				</a-form>
			</a-card>

			<!-- Step 4  :  -->
			<a-card v-else-if="step == 3" :bordered="false" class="header-solid">

				<h5 class="font-regular text-center">Crie Impacto Visual!</h5>
				<p class="text-center">Adicione ao menos uma imagem que representa seu conteúdo</p>

				<!-- form Curso-->
				<a-form :form="form" class="mt-30" :hideRequiredMark="true">
					<a-row :gutter="[24]">
						<a-col :span="24">
								<a-form-item class="mb-10" :colon="false">
									<a-upload
										name="avatar"
										list-type="picture-card"
										class="projects-uploader"
										:show-upload-list="false"
										style="height: 150px;"
										@change="handleFileChange"

									>
										<div v-if="imageUrl"><div class="ant-upload-text font-regular text-muted">
												{{ imageUrl }}
											</div></div>
										<div v-else>
											<div class="ant-upload-text font-regular text-muted">
												<a-icon type="plus-circle" theme="filled"  :style="{ fontSize: '25px' }" /><br>
												Selecione uma Capa<br><span style="font-size: smaller;">Proporções 16:9 (1920 x 1080)</span>
											</div>
										</div>
									</a-upload>
								</a-form-item>
							</a-col>
					</a-row>
					<a-row :gutter="[24]">
						<a-col :span="24">
								<a-form-item class="mb-10" :colon="false">
									<a-upload
										name="avatar"
										list-type="picture-card"
										class="projects-uploader"
										:show-upload-list="false"
										style="height: 150px;"
										@change="handleFileChange2"

									>
										<div v-if="imageUrl2"><div class="ant-upload-text font-regular text-muted">
												{{ imageUrl2 }}
											</div></div>
										<div v-else>
											<div class="ant-upload-text font-regular text-muted">
												<a-icon type="plus-circle" theme="filled" :style="{ fontSize: '25px' }" /><br>
												Selecione uma logo<br><span style="font-size: smaller;">Proporções 1:1 (500 x 500)</span>
											</div>
										</div>
									</a-upload>
								</a-form-item>
							</a-col>
					</a-row>
					<a-row :gutter="[24]">
						<a-col :span="24">
								<a-form-item class="mb-10" :colon="false">
									<a-upload
										name="avatar"
										list-type="picture-card"
										class="projects-uploader"
										:show-upload-list="false"
										style="height: 150px;"
										@change="handleFileChange3"

									>
										<div v-if="imageUrl3"><div class="ant-upload-text font-regular text-muted">
												{{ imageUrl3 }}
											</div></div>
										<div v-else>
											<div class="ant-upload-text font-regular text-muted">
												<a-icon type="plus-circle" theme="filled" :style="{ fontSize: '25px' }" /><br>
												Selecione um Capa Story<br><span style="font-size: smaller;">Proporções 9:16 (1080 x 1920)</span>
											</div>
										</div>
									</a-upload>
								</a-form-item>
							</a-col>
					</a-row>
					<a-row :gutter="[24]" style="margin-top: 25px;">
						<a-col :span="24" class="text-right">
							<a-button type="primary" @click="sendImages()" class="px-25" :disabled="isDisabled" :loading="isLoadingSaveContent"
								:block="!isDesktop">PUBLICAR</a-button>
						</a-col>
					</a-row>
				</a-form>
			</a-card>
		</div>
		<!-- / Wizard form cards -->
	</div>
</template>

<script>

export default {
	components: {
	},
	data() {
		return {
			id: null,
			isSaveProgress: false,
			isUploadInProgress: false,
			event: {
				name: null,
				description: null,
				descriptionType: null,
				format: null,
				zipcode: "59000000",
				address: null,
				state: "NN",
				addressNumber: "SN",
				city: "NNN",
				district: "NNN",
				date: null,
				vacancies: 0,
				checkoutLink: null,
				startDate: null,
				price: 0,
				courseId: null
			},
			product: {
				link: null,
				name: null,
				description: null,
				descriptionType: null,
				startDate: '2023-11-08',
				price: 0,
				blocked: true,
				productTypeId: null
			},
			course: {
				title: null,
				description: null,
				price: 0,
				descriptionType: null,
				categoryId:  null,
			},
			checked: true,
			isDisabled: true,
			isLoadingSaveContent: false,
			imageUrl: null,
			imageUrl2: null,
			imageUrl3: null,
			banner: {
				'1x1': null,
				'16x9': null,
				'9x16': null
			},
			logo: {
				'1x1': null,
				'16x9': null,
				'9x16': null
			},
			productImages: {
				banner: null,
				logo: null,
				productId: null
			},
			eventImages: {
				banner: null,
				logo: null,
				eventId: null
			},
			courseImages: {
				banner: null,
				logo: null,
				courseId: null
			},
			// Active step.
			step: 0,
			isDesktop: window.innerWidth > 768, // Defina aqui o tamanho limite para considerar como "desktop"

			// Checkbox'es array of checked options.
			checkedList: [],

			// Checkbox'es array of all options.
			checkboxOptions: ['event', 'course', 'product'],

			// Step's form object
			form: this.$form.createForm(this, { name: 'steps' }),

		}
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	computed: {
		formattedDate() {
      // Converte o objeto Moment para o formato desejado
      return this.event.date ? this.event.date.format('YYYY-MM-DD') : null;
    },
  },
	methods: {
		handleSubmit(e) {
			e.preventDefault();
			this.form.validateFields((err, values) => {
				if (!err) {
				console.log('Received values of form: ', values);
				}
			});
			},
		renameTitle(category){

			switch (category) {
				case 'event':
					return 'Evento'
				case 'course':
					return 'Curso'
				default:
				    return 'Produto'
			}

		},
		sendImages(){

			this.isLoadingSaveContent = true;

			const type = this.checkedList[0];
			var form = null;

			if(type == 'event'){

				this.eventImages.banner = this.banner;
				this.eventImages.eventId = this.id;
				form = this.eventImages;

			}else if(type == 'course'){

				this.courseImages.banner = this.banner;
				this.courseImages.courseId = this.id;
				form = this.courseImages;

			}else{

				this.productImages.banner = this.banner;
				this.productImages.productId = this.id;
				form = this.productImages;
			}

			this.$api.dataService.patch(`/${type}/images`,form)
				.then((res) => {

					console.log(res);
					this.isLoadingSaveContent = false;
					this.$router.push("/productor/contents");

				}).catch((err) =>{

					console.log(err);
				})

		}, 
		async handleFileChange(info) {

			if (this.isUploadInProgress || this.banner['1x1'] !== null) {
				// Upload já em andamento ou imagem já existe, não faça nada
				return;
				}

			    this.isUploadInProgress = true;

				const file = info.file.originFileObj;
				// Configurar a URL e os parâmetros da requisição
				const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/consigmais/upload';
				const apiKey = '849353588224476';
				const uploadPreset = 'cashman-doc';

				// Criar um objeto FormData para enviar o arquivo
				const formData = new FormData();
				formData.append('file', file);
				formData.append('upload_preset', uploadPreset);

				// Configurar cabeçalhos
				const headers = new Headers();

				// Configurar as opções da requisição
				const options = {
					method: 'POST',
					headers,
					body: formData,
				};

				try {
					// Fazer a requisição para o Cloudinary usando fetch
					const response = await fetch(`${cloudinaryUrl}?api_key=${apiKey}`, options);

					if (!response.ok) {
						throw new Error('Erro na requisição');
					}

					const responseData = await response.json();
						this.banner['1x1'] = responseData.secure_url

					// Lidar com o sucesso do upload
					this.imageUrl = responseData.public_id;
				} catch (error) {
					console.error('Erro ao fazer upload para o Cloudinary:', error);
				} finally {
					this.isUploadInProgress = false;
				}
			 
		},
		async handleFileChange2(info) {

			if (this.isUploadInProgress || this.banner['16x9'] !== null) {
				// Upload já em andamento ou imagem já existe, não faça nada
				return;
				}

				this.isUploadInProgress = true;

				const file = info.file.originFileObj;
				// Configurar a URL e os parâmetros da requisição
				const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/consigmais/upload';
				const apiKey = '849353588224476';
				const uploadPreset = 'cashman-doc';

				// Criar um objeto FormData para enviar o arquivo
				const formData = new FormData();
				formData.append('file', file);
				formData.append('upload_preset', uploadPreset);

				// Configurar cabeçalhos
				const headers = new Headers();

				// Configurar as opções da requisição
				const options = {
					method: 'POST',
					headers,
					body: formData,
				};

				try {
					// Fazer a requisição para o Cloudinary usando fetch
					const response = await fetch(`${cloudinaryUrl}?api_key=${apiKey}`, options);

					if (!response.ok) {
						throw new Error('Erro na requisição');
					}

					const responseData = await response.json();
						this.banner['16x9'] = responseData.secure_url

					// Lidar com o sucesso do upload
					this.imageUrl2 = responseData.public_id;
				} catch (error) {
					console.error('Erro ao fazer upload para o Cloudinary:', error);
				} finally {
					this.isUploadInProgress = false;
				}
			
			},
			async handleFileChange3(info) {

if (this.isUploadInProgress || this.banner['9x16'] !== null) {
	// Upload já em andamento ou imagem já existe, não faça nada
	return;
	}

	this.isUploadInProgress = true;

	const file = info.file.originFileObj;
	// Configurar a URL e os parâmetros da requisição
	const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/consigmais/upload';
	const apiKey = '849353588224476';
	const uploadPreset = 'cashman-doc';

	// Criar um objeto FormData para enviar o arquivo
	const formData = new FormData();
	formData.append('file', file);
	formData.append('upload_preset', uploadPreset);

	// Configurar cabeçalhos
	const headers = new Headers();

	// Configurar as opções da requisição
	const options = {
		method: 'POST',
		headers,
		body: formData,
	};

	try {
		// Fazer a requisição para o Cloudinary usando fetch
		const response = await fetch(`${cloudinaryUrl}?api_key=${apiKey}`, options);

		if (!response.ok) {
			throw new Error('Erro na requisição');
		}

		const responseData = await response.json();
			this.banner['9x16'] = responseData.secure_url

		// Lidar com o sucesso do upload
		this.imageUrl3 = responseData.public_id;
	} catch (error) {
		console.error('Erro ao fazer upload para o Cloudinary:', error);
	} finally {
		this.isUploadInProgress = false;
	}

},
					
		saveContent(){

			this.isSaveProgress = true;

			const type = this.checkedList[0];
			var form = null;

			if(type == 'event'){

				form = this.event;

			}else if(type == 'course'){

				form = this.course;

			}else{
				
				form = this.product;
			}

			this.$api.dataService.save(type,form)
				.then((res) => {

					this.isSaveProgress = false;
					this.moveStep(1);
					this.id = res.id;

				}).catch((err) =>{

					this.isSaveProgress = false;
					console.log(err);
				})
			
		},
		onChange(){
			if(this.event.date){
			const dateString = this.event.date.format('YYYY-MM-DD');

     		 console.log(dateString);
		}
    },
		handleResize() {
			this.isDesktop = window.innerWidth > 768; // Atualize o estado com base no tamanho da tela
		},

		// Move step by a distance.
		moveStep(distance) {
			this.onChange();
			this.step += distance;
		},

		// Toggle an item from the checkbox.
		toggleCheckbox(item) {
			this.isDisabled = false;

			if (this.checkedList.includes(item)) {
				// Desmarcar a opção se ela já estiver selecionada
				const index = this.checkedList.indexOf(item);
				this.checkedList.splice(index, 1);

			} else {
				// Limpar a seleção existente e selecionar a nova opção
				this.checkedList = [item];
			}
		},

	},
}

</script>

<style lang="scss">
.fill-dark {
	fill: #50505b;
}

.checkbox-label {
	transition: transform 0.2s ease; // Adicione uma transição suave para a propriedade 'transform'.
	cursor: pointer;

	&:hover {
		// Adicione um efeito de hover se desejar.
		transform: scale(1.1);
	}
}

.active {
	transform: scale(1.1); // Elevar ligeiramente o elemento quando estiver ativo.
}

/* Estilos personalizados para a versão mobile */
.ant-steps:not(.ant-steps-horizontal) .ant-steps-item-container {
	display: flex !important;
	flex-direction: column !important;
	/* Outros estilos necessários para a versão mobile */
}

h6 {
	color: #ffffff !important;
}

/* Estilo específico para h6 em telas menores (por exemplo, largura máxima de 768px) */
@media (max-width: 768px) {
	h6 {
		font-size: 12px !important;
		/* Tamanho de fonte para telas menores */
	}
}
</style>